var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("main", { staticClass: "Privacy" }, [
      _c("img", {
        staticClass: "Privacy__logo mb-30 mt-30",
        attrs: { alt: "Bobtail logo", src: require("../../assets/logo.png") }
      }),
      _c("div", { staticClass: "mb-30" }, [
        _c("h1", { staticClass: "fw-med" }, [
          _vm._v("BOBTAIL, INC., PRIVACY POLICY")
        ]),
        _c("label", [_vm._v("Last Revised: September 09, 2020")]),
        _c("p", { staticClass: "mt-8" }, [
          _vm._v(
            'This Privacy Policy explains how information about you is collected, used and disclosed by Bobtail, Inc. and its subsidiaries and affiliated companies ("Bobtail"). This Privacy Policy applies to information we collect when you use our website, available at bobtail.com, any successor website, and other online services that link to this Privacy Policy (collectively, the "Site") or when you otherwise interact with us.'
          )
        ])
      ]),
      _c("div", { staticClass: "mb-30" }, [
        _c("h2", { staticClass: "mb-8" }, [_vm._v("Modifications")]),
        _c("p", [
          _vm._v(
            "We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of the policy and, in some cases, we may provide you with additional notice (such as adding a statement to our homepage or sending you a notification). We encourage you to review the Privacy Policy whenever you access the Site to stay informed about our information practices and the ways you can help protect your privacy."
          )
        ])
      ]),
      _c("div", { staticClass: "mb-30" }, [
        _c("h2", { staticClass: "mb-8" }, [
          _vm._v("Collection of Information")
        ]),
        _c("p", { staticClass: "mb-8" }, [
          _c("strong", [_vm._v("Information You Provide Us.")]),
          _vm._v(
            ' We collect information you provide directly to us. For example, we collect information when you fill out a form, communicate with us via third party social media sites, request information, participate in any interactive features of the Site, submit content, or otherwise communicate with us. The types of information we may collect include your name, email address, postal address, phone number, information about shipments (such as the origination, destination, customer and payment amounts), and any other information you choose to provide. In addition, if you choose to link your financial account to the Site, we may collect information, such as your financial institution\'s name, names of the financial account(s), account number(s), account balance(s), and certain transaction information (date, merchant, and amount of the transaction) for accounts that you elect to connect to the platform. For more details on how we collect this type of information, please review the information about Plaid in the "Information We Collect From Other Sources" section, below.'
          )
        ]),
        _c("p", [
          _c("strong", [
            _vm._v(
              "Information We Collect Automatically When You Use the Site."
            )
          ]),
          _vm._v(
            " When you access or use our Site, we may automatically collect information about you, including:"
          )
        ]),
        _c("ul", [
          _c("li", { staticClass: "mb-8" }, [
            _vm._v(
              "Log Information: We log information about your use of the Site, including the type of browser you use, access times, pages viewed, your IP address and the page you visited before navigating to our Site."
            )
          ]),
          _c("li", { staticClass: "mb-8" }, [
            _vm._v(
              "Device Information: We collect information about the computer or mobile device you use to access our Site, including the hardware model, operating system and version, unique device identifiers and mobile network information."
            )
          ]),
          _c("li", [
            _vm._v(
              'Information Collected by Cookies and Other Tracking Technologies:  We (and our service providers) may use various technologies to collect information, including cookies and web beacons. Cookies are small data files stored on your hard drive or in device memory that help us to improve our Site and your experience, see which areas and features of our Site are popular and count visits. Web beacons are electronic images that may be used in our Site or emails and help deliver cookies, count visits, and understand usage and campaign effectiveness. For more information about cookies, and how to disable them, please see "Your Choices" below.'
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "mb-30" }, [
        _c("h2", { staticClass: "mb-8" }, [
          _vm._v("Information We Collect From Other Sources")
        ]),
        _c("p", { staticClass: "mb-8" }, [
          _vm._v(
            "We may also obtain information from other sources and combine that with information we collect through our Site. For example, if you interact with us via a social media site, we will have access to certain information from that site, such as your name, account information and friends lists, in accordance with the authorization procedures determined by such social media site."
          )
        ]),
        _c("p", { staticClass: "mb-8" }, [
          _vm._v(
            'In addition, our Site offers users the ability to integrate your Gmail account with your Bobtail account through a third-party service, called Filestack, which allows users to pull attachments directly from emails in your Gmail account. If you choose to integrate your Gmail account to your Bobtail account, we may collect information from or related to that account. For example, this information may include your information about email attachments you choose to integrate (such as file name and file size). You can read more about your privacy choices in the "Third-party integrations" portion of the "Your Choices" section, below. For more information about Filestack, you can visit '
          ),
          _c("a", { attrs: { href: "https://www.filestack.com/" } }, [
            _vm._v("Filestack.com")
          ]),
          _vm._v(" and review Filestack's privacy policy, available at "),
          _c("a", { attrs: { href: "https://www.filestack.com/privacy/" } }, [
            _vm._v("https://www.filestack.com/privacy/")
          ]),
          _vm._v(".")
        ]),
        _c("p", [
          _vm._v(
            'Our Site also offers users the ability to integrate your financial accounts with your Bobtail account through a third-party service, called Plaid, which allows us to facilitate payments into your connected accounts. If you choose to integrate your financial accounts with your Bobtail account, we may collect information from or related to these accounts, including account number(s), routing information, and information about transactions in the account(s). You can read more about your privacy choices in the "Third-party integrations" portion of the "Your Choices" section, below. For more information about Plaid, you can review the "Payment Integrations" section, below.'
          )
        ])
      ]),
      _c("div", { staticClass: "mb-30" }, [
        _c("h2", { staticClass: "mb-8" }, [_vm._v("Use of Information")]),
        _c("p", { staticClass: "mb-8" }, [
          _vm._v(
            "We may use information about you for various purposes, including to:"
          )
        ]),
        _c("ul", [
          _c("li", { staticClass: "mb-8" }, [
            _vm._v("Provide, maintain and improve our Site;")
          ]),
          _c("li", { staticClass: "mb-8" }, [
            _vm._v(
              "Communicate with you about products, services, offers, promotions, rewards, and events offered by Bobtail and others, and provide news and information we think will be of interest to you;"
            )
          ]),
          _c("li", { staticClass: "mb-8" }, [
            _vm._v(
              "Respond to your comments, questions and requests and provide customer service;"
            )
          ]),
          _c("li", { staticClass: "mb-8" }, [
            _vm._v(
              "Monitor and analyze trends, usage and activities in connection with our Site;"
            )
          ]),
          _c("li", { staticClass: "mb-8" }, [
            _vm._v(
              "Personalize and improve the Site and provide advertisements, content or features that match user profiles or interests;"
            )
          ]),
          _c("li", { staticClass: "mb-8" }, [
            _vm._v(
              "Link or combine with information we get from others to help understand your needs and provide you with better service; and"
            )
          ]),
          _c("li", [
            _vm._v(
              "Carry out any other purpose for which the information was collected."
            )
          ])
        ]),
        _c("p", [
          _vm._v(
            "Bobtail is based in the United States and the information we collect is governed by U.S. law. By accessing or using the Site or otherwise providing information to us, you consent to the processing and transfer of information in and to the U.S. and other countries."
          )
        ])
      ]),
      _c("div", { staticClass: "mb-30" }, [
        _c("h2", { staticClass: "mb-8" }, [_vm._v("Sharing of Information")]),
        _c("p", [
          _vm._v(
            "We may share information about you as follows or as otherwise described in this Privacy Policy:"
          )
        ]),
        _c("ul", [
          _c("li", { staticClass: "mb-8" }, [
            _vm._v(
              "With vendors, consultants and other service providers who need access to such information to carry out work on our behalf;"
            )
          ]),
          _c("li", { staticClass: "mb-8" }, [
            _vm._v(
              "In response to a request for information if we believe disclosure is in accordance with any applicable law, regulation or legal process, or as otherwise required by any applicable law, rule or regulation;"
            )
          ]),
          _c("li", { staticClass: "mb-8" }, [
            _vm._v(
              "If we believe your actions are inconsistent with our user agreements or policies, or to protect the rights, property and safety of Bobtail or others;"
            )
          ]),
          _c("li", { staticClass: "mb-8" }, [
            _vm._v(
              "In connection with, or during negotiations of, any merger, sale of company assets, financing or acquisition of all or a portion of our business to another company; and"
            )
          ]),
          _c("li", [
            _vm._v(
              "With your consent or at your direction, including if we notify you through our Site that the information you provide will be shared in a particular manner and you provide such information."
            )
          ])
        ]),
        _c("p", [
          _vm._v(
            "We may also share aggregated or de-identified information, which cannot reasonably be used to identify you."
          )
        ])
      ]),
      _c("div", { staticClass: "mb-30" }, [
        _c("h2", { staticClass: "mb-8" }, [_vm._v("Payment Integrations")]),
        _c("p", { staticClass: "mb-8" }, [
          _vm._v("We work with a company called Plaid ("),
          _c("a", { attrs: { href: "http://www.plaid.com" } }, [
            _vm._v("www.plaid.com")
          ]),
          _vm._v(
            ") to gather data for you about your financial accounts from the financial institutions where the accounts are held and from Plaid. By connecting your bank account through Plaid, you authorize us to share information with Plaid to authenticate you as an authorized user and grant Plaid and any other third party provider they use the right, power, and authority to act on your behalf to access and transmit your personal and financial information from the relevant financial institution according to the terms of Plaid's privacy policy, available at "
          ),
          _c(
            "a",
            {
              attrs: {
                href: "https://plaid.com/legal/#end-user-privacy-policy"
              }
            },
            [_vm._v("https://plaid.com/legal/#end-user-privacy-policy")]
          ),
          _vm._v(", or that of any other third party provider.")
        ]),
        _c("p", [
          _vm._v(
            "Additionally, we work with a company called Modern Treasury (www.moderntreasury.com) to facilitate wire transfers to users. By connecting your financial accounts and using the Site and our services, you grant Modern Treasury the right, power, and authority to collect and use information about you according to the terms of Modern Treasury's privacy policy, available at "
          ),
          _c(
            "a",
            {
              attrs: { href: "https://www.iubenda.com/privacy-policy/58925569" }
            },
            [_vm._v("https://www.iubenda.com/privacy-policy/58925569")]
          ),
          _vm._v(".")
        ])
      ]),
      _c("div", { staticClass: "mb-30" }, [
        _c("h2", { staticClass: "mb-8" }, [_vm._v("Social Sharing Features")]),
        _c("p", [
          _vm._v(
            'The Site may offer social sharing features and other integrated tools (such as the Facebook "Like" button), which let you share actions you take on our Site with other media, and vice versa. The use of such features enables the sharing of information with your friends or the public, depending on the settings you establish with the entity that provides the social sharing feature. For more information about the purpose and scope of data collection and processing in connection with social sharing features, please visit the privacy policies of the entities that provide these features.'
          )
        ])
      ]),
      _c("div", { staticClass: "mb-30" }, [
        _c("h2", { staticClass: "mb-8" }, [
          _vm._v("Advertising and Analytics Site Provided by Others")
        ]),
        _c("p", [
          _vm._v(
            "We may allow others to serve advertisements on our behalf across the Internet and to provide analytics services. These entities may use cookies, web beacons and other technologies to collect information about your use of the Site and other websites, including your IP address, web browser, pages viewed, time spent on pages, links clicked and conversion information. This information may be used by Bobtail and others to, among other things, analyze and track data, determine the popularity of certain content, deliver advertising and content targeted to your interests on our Site and other websites and better understand your online activity. For more information about Internet-based ads, or to opt out of having your web browsing information used for behavioral advertising purposes, please visit "
          ),
          _c(
            "a",
            {
              attrs: { href: "www.networkadvertising.org/managing/opt_out.asp" }
            },
            [_vm._v("www.networkadvertising.org/managing/opt_out.asp")]
          ),
          _vm._v(" and "),
          _c("a", { attrs: { href: "www.aboutads.info/choices" } }, [
            _vm._v("www.aboutads.info/choices")
          ]),
          _vm._v(".")
        ])
      ]),
      _c("div", { staticClass: "mb-30" }, [
        _c("h2", { staticClass: "mb-8" }, [_vm._v("Security")]),
        _c("p", [
          _vm._v(
            "Bobtail takes reasonable measures to help protect information about you from loss, theft, misuse and unauthorized access, disclosure, alteration and destruction."
          )
        ])
      ]),
      _c("div", { staticClass: "mb-30" }, [
        _c("h2", { staticClass: "mb-8" }, [_vm._v("Your Choices")]),
        _c("p", { staticClass: "mb-8" }, [
          _c("strong", [_vm._v("Account Information.")]),
          _vm._v(
            " You may update, correct or delete information about you at any time by emailing us with your request at "
          ),
          _c("a", { attrs: { href: "mailto:tech@bobtail.com" } }, [
            _vm._v("tech@bobtail.com")
          ]),
          _vm._v(
            ", but note that we may retain certain information as required by law or for legitimate business purposes. We may also retain cached or archived copies of information about you for a certain period of time."
          )
        ]),
        _c("p", { staticClass: "mb-8" }, [
          _c("strong", [_vm._v("Cookies.")]),
          _vm._v(
            " Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove or reject browser cookies. Please note that if you choose to remove or reject cookies, this could affect the availability and functionality of our Site."
          )
        ]),
        _c("p", { staticClass: "mb-8" }, [
          _c("strong", [_vm._v("Promotional Communications.")]),
          _vm._v(
            " You may opt out of receiving promotional emails or messages from Bobtail by following the instructions in those emails or messages. If you opt out, we may still send you non-promotional communications, such as those about our ongoing service relationship."
          )
        ]),
        _c("p", [
          _c("strong", [_vm._v("Third-party integrations. ")]),
          _vm._v(
            " If you withdraw our ability to access certain information from your Gmail account, that choice will not apply to information that we have already received from your account. If you withdraw our ability to access financial information through Plaid, that choice will not apply to information that we have already received from your account and it may impact our ability to facilitate payments to you."
          )
        ])
      ]),
      _c("div", { staticClass: "mb-30" }, [
        _c("h2", { staticClass: "mb-8" }, [_vm._v("Contact Us")]),
        _c("p", [
          _vm._v(
            "If you have any questions about this Privacy Policy, please contact us at "
          ),
          _c("a", { attrs: { href: "mailto:hello@bobtail.com" } }, [
            _vm._v("hello@bobtail.com")
          ]),
          _vm._v(".")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }